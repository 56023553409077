import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { Checkmark, CircleFalse } from '@combateafraude/icons/general';

const { Option } = Select;

const SelectModel = ({ values, onChange, featureFlag, profileTemplates }) => {
  const [modelsSearched, setModelsSearched] = useState([]);
  const [models, setModels] = useState([]);

  const modelsOptions = values?.map((v) => {
    const template = profileTemplates?.find((el) => {
      if (el._id === v) {
        return el;
      }
      return null;
    });
    return template?.name || null;
  });

  const featureFlagModels =
    values?.map((c, index) => ({
      key: c,
      value: c,
      label: modelsOptions[index],
    })) || null;

  useEffect(() => {
    if (featureFlag.name === 'alwaysDocumentscopy') {
      const modelsFiltered = profileTemplates?.filter((template) =>
        template?.product?.toUpperCase().includes('COMBATE_A_FRAUDE')
      );

      setModelsSearched(modelsFiltered || []);
      setModels(modelsFiltered || []);
    } else {
      setModelsSearched(profileTemplates);
      setModels(profileTemplates);
    }
  }, [profileTemplates, featureFlag]);

  const onModelsSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setModelsSearched(models);
      }

      const modelsFiltered = models?.filter((model) => {
        if (model?.name?.toUpperCase().includes(searchTerm?.toUpperCase())) {
          return model;
        }

        return false;
      });

      setModelsSearched(modelsFiltered || []);
    },
    [models]
  );

  return (
    <>
      <Select
        labelInValue
        showSearch
        allowClear
        mode="multiple"
        maxTagCount={1}
        defaultActiveFirstOption={false}
        optionFilterProp="label"
        style={{ width: 400, marginLeft: 50 }}
        value={featureFlagModels || []}
        placeholder="Busque por modelo..."
        dropdownClassName="multiselect-filters"
        filterOption={false}
        listHeight={300}
        dropdownAlign={{ offset: [0, 1] }}
        removeIcon={<CircleFalse />}
        clearIcon={<CircleFalse />}
        menuItemSelectedIcon={<Checkmark />}
        notFoundContent="Busque por modelo..."
        onSearch={onModelsSearch}
        onClear={() => setModelsSearched(models)}
        onChange={(value) => {
          onChange(value);
          setModelsSearched(models);
        }}
      >
        {modelsSearched?.map((m) => (
          <Option key={m._id} label={m.name} value={m._id}>
            <span className="option-title">{m.name}</span>
          </Option>
        ))}
      </Select>
      {featureFlagModels?.length === 0 && featureFlag.name !== 'bypassCpfCheckOcr' && (
        <>
          <p className="gx-text-danger mrg-left-100">
            Selecione pelo menos um modelo de consulta
          </p>
        </>
      )}
    </>
  );
};

SelectModel.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  featureFlag: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectModel;
