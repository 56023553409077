import React, { useCallback, useEffect } from 'react';
import { Col, Form, InputNumber, message, Row, Tooltip } from 'antd';

import Button from 'components/Button';
import { useParams } from 'react-router-dom';
import { useCustomerConfigs } from 'hooks/customerConfigs';
import { Checkmark, Trash } from '@combateafraude/icons/general';

// import { Container } from './styles';

function UpdateExecutionLimitForm() {
  const {
    patchCustomerExecutionLimit,
    loadingCustomerExecutionLimit,
    loadingPatchCustomerExecutionLimit,
    loadCustomerExecutionLimit,
    deleteCustomerExecutionLimit,
    loadingDeleteCustomerExecutionLimit,
    customerExecutionLimit,
  } = useCustomerConfigs();

  const { tenantId } = useParams();

  const [form] = Form.useForm();

  const refreshExecutionLimit = useCallback(async () => {
    try {
      await loadCustomerExecutionLimit({ tenantId });
      form.setFieldsValue({
        executionLimit: customerExecutionLimit,
      });
    } catch (error) {
      message.error(`Houve um erro ao carregar os créditos de execuções desse cliente.`);
    }
  }, [tenantId, loadCustomerExecutionLimit, customerExecutionLimit, form]);

  useEffect(() => refreshExecutionLimit(), [refreshExecutionLimit, tenantId]);

  const handlePatchExecutionLimit = useCallback(async () => {
    try {
      const payload = await form.validateFields();

      await patchCustomerExecutionLimit({
        tenantId,
        payload,
      });

      message.success('Créditos atualizado com sucesso.');
    } catch (error) {
      message.error('Erro ao atualizar os Créditos.');
    }
    refreshExecutionLimit();

    return null;
  }, [form, patchCustomerExecutionLimit, tenantId, refreshExecutionLimit]);

  const handleDeleteExecutionLimit = useCallback(async () => {
    await deleteCustomerExecutionLimit({ tenantId });
    refreshExecutionLimit();
  }, [deleteCustomerExecutionLimit, tenantId, refreshExecutionLimit]);

  return (
    <Form
      form={form}
      layout="vertical"
      className="mrg-top-10"
      onFinish={handlePatchExecutionLimit}
    >
      <Row align="middle" justify="space-between">
        <Col span={14}>
          <Form.Item
            className="text-bold text-dark"
            name="executionLimit"
            label="Créditos na criação de consultas:"
            rules={[
              {
                required: true,
                validator: (_rule, value) => {
                  const numbers = /^[0-9]+$/;

                  const isValid = value?.toString()?.trim()?.match(numbers);
                  if (!isValid) {
                    return Promise.reject(
                      new Error(
                        'Os créditos deve ser um numero inteiro maior ou igual à zero'
                      )
                    );
                  }

                  return Promise.resolve(value);
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              step={1}
              defaultValue={customerExecutionLimit}
              disabled={
                loadingCustomerExecutionLimit || loadingPatchCustomerExecutionLimit
              }
              loading={
                loadingCustomerExecutionLimit || loadingPatchCustomerExecutionLimit
              }
              className="text-dark"
              placeholder="0"
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Tooltip title="Remover créditos">
            <Button
              className="btn-custom btn-custom-danger"
              htmlType="button"
              loading={loadingDeleteCustomerExecutionLimit}
              disabled={
                loadingDeleteCustomerExecutionLimit || loadingCustomerExecutionLimit
              }
              onClick={handleDeleteExecutionLimit}
            >
              <Trash width={20} height={20} />
            </Button>
          </Tooltip>
          <Tooltip title="Atualizar créditos">
            <Button
              className="btn-custom btn-custom-primary"
              htmlType="submit"
              disabled={
                loadingCustomerExecutionLimit || loadingPatchCustomerExecutionLimit
              }
              loading={loadingPatchCustomerExecutionLimit}
            >
              <Checkmark width={20} height={20} />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  );
}

export default UpdateExecutionLimitForm;
