import React, { useMemo, useState, useCallback } from 'react';
import { Modal, Form, Divider, Row } from 'antd';
import { Close } from '@combateafraude/icons/general';
import Button from 'components/Button';

import PageTitle from 'components/PageTitle';
import UpdateExecutionLimitForm from './UpdateExecutionLimitForm';
import UpdateFeatureFlagsForm from './UpdateFeatureFlagsForm';

const ExecutionLimit = () => {
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const UpdateConfigurationsModal = useMemo(() => {
    return (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        destroyOnClose
        closeIcon={<Close width={18} height={18} />}
        onCancel={closeModal}
        footer={null}
        width={1200}
        centered
      >
        <PageTitle
          title="Atualizar configurações"
          subtitle="Defina as configurações do cliente."
        />
        <Divider className="mrg-top-10">
          Total de créditos na criação de consultas
        </Divider>
        <UpdateExecutionLimitForm />
        <Divider className="mrg-top-10">Features Habilitadas</Divider>
        <UpdateFeatureFlagsForm />

        <Divider className="mrg-top-10" />

        <Row align="middle" justify="end">
          <Button onClick={closeModal}>Fechar</Button>
        </Row>
      </Modal>
    );
  }, [form, modalVisible, closeModal]);

  return {
    openModal,
    closeModal,
    UpdateConfigurationsModal,
  };
};

export default ExecutionLimit;
