export const FEATURE_FLAGS = [
  {
    name: 'useCustomStatus',
    label: 'Status Personalizados:',
    tooltip:
      'Permite a criação de novos valores para o status final da regra, além dos que já existem ("Aprovado", "Reprovado" e "Pendente").',
  },
  {
    name: 'bypassDnsWebhook',
    label: 'Bypass Dns webhook:',
    tooltip: 'Utiliza o dns do google para enviar o webhook para o cliente.',
  },
  {
    name: 'useRulesSubjectTitle',
    label: 'Visualizar novos títulos das regras de compliance:',
    tooltip: 'Exibe títulos mais claros e descritivos para as regras de compliance.',
  },
  {
    name: 'useClientManualOcr',
    label: 'Habilitar edição de OCR pelo cliente:',
    tooltip: 'Permite ao cliente ajustar dados lidos pela OCR no painel Trust.',
  },
  {
    name: 'profilesModule',
    label: 'Visualizar conteudo relacionado aos Perfis:',
    tooltip:
      'Habilita acesso à seção de Perfis do Trust, incluindo Perfis de pessoa jurídica e física; Rotinas de Recorrência.',
  },
  {
    name: 'costPrices',
    label: 'Esconder valores de custo no Trust:',
    hasDependency: 'isWorkflowBuilder',
    tooltip:
      'Oculta os valores dos serviços e fontes, mantendo apenas a parte de consumo, excluindo a seção financeira.',
  },
  {
    name: 'faceLivenessExport',
    label: 'Habilitar exportação de CSV da Prova de Vida:',
    tooltip: 'Habilita a funcionalidade de exportar CSV na lista de liveness realizados.',
  },
  {
    name: 'isWorkflowBuilder',
    label: 'Habilitar novo workflow builder para internacionalização:',
    enableAnother: 'costPrices',
    tooltip:
      'Ativa o fluxo internacional completo, incluindo plataforma, consultas, builder, etc.',
  },
  {
    name: 'livenessBlock',
    label: 'Habilitar nova feature trava-liveness:',
    tooltip:
      'Exclusivo do Liveness CAF, esta trava restringe a realização de apenas 5 tentativas de Liveness/FA para o mesmo CPF. O número de tentativas é configurável, mas o padrão é 5.',
  },
  {
    name: 'iProovPFL',
    label: 'Habilitar Liveness iProov',
    tooltip: 'Ativa funcionalidades relacionadas ao Liveness Iproov.',
  },
  {
    name: 'onboardingOneTimeLink',
    label: 'Link de Onboarding com acesso uma única vez',
    tooltip:
      'Ativa funcionalidade no Trust para criar link de Onboarding com acesso único.',
  },
  // ! noficationIproovMigration FLAG TEMPORÁRIA ATÉ OS CLIENTES MIGRAREM PARA IPROOV
  {
    name: 'noficationIproovMigration',
    label: 'Habilitar notificação para migração iProov (Trust)',
    tooltip:
      'Ativa um alerta de migração do Liveness CAF para Iproov ao acessar a dashboard ou consultas do Trust.',
  },
  {
    name: 'additionalFieldsCsvExtraction',
    label: 'Campos extras na extração de CSV',
    options: ['templateId', 'templateName'],
    tooltip:
      'Habilita a inclusão de campos adicionais durante o processo de extração de dados para um arquivo CSV. Ao habilitar esta opção, é possível especificar quais campos adicionais devem ser incluídos, fornecendo informações detalhadas sobre os dados extraídos.',
  },
  // {
  //   name: 'clearSalePFL',
  //   label: 'Habilitar Liveness ClearSale',
  // },
  // ! 3 ITEMS BELOW USE QUERY TEMPLATE, LEAVE LAST
  {
    name: 'isPriorityGovernmentBiometric',
    label: 'Habilitar consulta prioritária em bases do governo:',
    hasModel: true,
    tooltip:
      'Prioriza consultas biométricas na base do governo antes de buscar resultados no all.id.',
  },
  {
    name: 'disableManualOcr',
    label: 'Desabilitar OCR Manual:',
    hasModel: true,
    tooltip:
      'Impede que consultas com documentos do tipo "others" fiquem pendentes de OCR manual.',
  },
  {
    name: 'alwaysDocumentscopy',
    label:
      'Habilitar verificação obrigatória na Documentoscopia para os seguintes Modelos de Consulta:',
    hasModel: true,
    tooltip:
      'Esta opção garantirá que todas as consultas, utilizando os modelos de consulta escolhidos, passem pela validação de documentoscopia. Os modelos permitidos para seleção se restringem aos que utilizam o pacote de Combate à Fraude.',
  },
  {
    name: 'enableShadowTraffic',
    label: 'Habilitar duplicação do tráfego de transações',
    isNumber: true,
    placeholder: 'Digite o percentual',
    tooltip:
      'Habilita a duplicação do tráfego de transações da esteira BR para o ambiente Beta, sendo estas processadas pela esteira Global e comparadas com as originais, levando em consideração que essa funcionalidade possui apenas a finalidade de testes. É necessário escolher o percentual de transações que serão duplicadas para o ambiente Beta, ponderando entre os valores disponíveis e volume do cliente em questão.',
  },
  {
    name: 'enableWorkloadRedirection',
    label: 'Habilitar redirecionamento do tráfego de transações',
    isNumber: true,
    placeholder: 'Digite o percentual',
    tooltip:
      'Habilita o redirecionamento do tráfego de transações da esteira BR para a esteira Global, assim possibilitando a migração dos clientes de forma transparente. É necessário escolher o percentual de transações que serão redirecionadas para o fluxo Global, ponderando entre os valores disponíveis e volume do cliente em questão.',
  },
  {
    name: 'facetecFaceAuthBuilderSteps',
    label: 'Habilitar FaceAuth FaceTec no Onboarding Builder',
    options: ['2D', '3D'],
    tooltip:
      'Habilita as etapas "FaceAuth FaceTec 3D" e "FaceAuth FaceTec 2D" no Onboarding Builder.',
  },
  {
    name: 'facetecLivenessBuilderSteps',
    label: 'Habilitar Liveness FaceTec no Onboarding Builder',
    options: ['2D', '3D'],
    tooltip:
      'Habilita as etapas "Liveness FaceTec 3D" e "Liveness FaceTec 2D" no Onboarding Builder.',
  },
  {
    name: 'bypassCpfCheckOcr',
    label: 'Desabilitar consulta de CPF no OCR',
    hasModel: true,
    tooltip:
      'Desabilita a consulta de CPF acoplada ao serviço de OCR para todas as transações do cliente. Ao fazer isso, os dados da consulta de CPF presentes em "sections.cpf" deixarão de existir e caso o cliente queira fazer essa consulta, ela deve ser configurada como fonte no Modelo de Consulta. Atenção: caso o cliente possua integração com o campo "sections.cpf", isso deve ser revisto, pois as outras consultas de CPF não salvam os dados nesse campo. Ao ligar e não selecionar nenhum modelo de consulta específico, a desativação ocorrerá para todos os modelos.',
  },
];
